import { memo, useState, useEffect, useCallback } from "react";
import { Button } from "antd";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { isEmpty, first } from "../../utils/fp";

import { useGptStore } from "../../store/gpt";
import Cover from "./components/Cover";
import { useGo } from "../../hooks/useGo";
import Nav from "./components/Nav";
import Description from "./components/Description";
import { useRouterGuards } from "../../hooks/useRouterGuards";

const Gpt = () => {
  useRouterGuards();
  const { gpt } = useGptStore();
  const [active, setActive] = useState<any>({});

  useEffect(() => {
    if (gpt && isEmpty(active)) {
      setActive(first(gpt));
    }
  }, [gpt, active]);
  const { go } = useGo();

  const onPreview = useCallback(() => {
    if (active.id) {
      go(`/gpt/${active.id}`);
    }
  }, [go, active]);

  useDocumentTitle("Submit GPT");

  return (
    <div className="flex w-full h-full">
      <Nav active={active} setActive={setActive} />

      <div className="flex-1  bg-white  relative overflow-y-auto  px-[72px] ">
        <Button
          className="absolute left-[20px] top-[20px] z-10"
          onClick={onPreview}
        >
          Perview
        </Button>
        <Cover gpt={active} />
        <Description gpt={active} />
      </div>
    </div>
  );
};

export default memo(Gpt);
