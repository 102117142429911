import { useMemo } from "react";
import { useTopGptStore } from "../../../store/topGpt";
import { filter, prop, take } from "../../../utils/fp";

export const useGptMap = () => {
  const { gpts } = useTopGptStore();

  const chatGPT = useMemo(
    () =>
      filter(
        (item: any) => prop("gizmo.author.display_name")(item) === "ChatGPT"
      )(gpts),
    [gpts]
  );
  const customs = useMemo(
    () =>
      filter(
        (item: any) => prop("gizmo.author.display_name")(item) !== "ChatGPT"
      )(gpts),
    [gpts]
  );

  const top10 = useMemo(() => take(10)(customs), [customs]);

  const top50 = useMemo(() => take(50)(customs), [customs]);

  const top100 = useMemo(() => take(100)(customs), [customs]);

  return {
    top10,
    top50,
    top100,
    chatGPT,
    gpts,
  };
};
