import { memo, useCallback, useState, useMemo } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { noEmpty, prop, first } from "../../../utils/fp";
import Share from "../../../components/Share/Share";
import EditDetails from "./EditDetails";
import { getCover } from "../../../utils/cdn";
import { getShareLink } from "../../../utils/link";

const Cover = ({ store, setActive, gpts }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onEdit = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const cover = useMemo(() => {
    const storeCover = getCover(store);
    if (storeCover) {
      return storeCover;
    }
    if (noEmpty(gpts)) {
      const gpt = first(gpts);
      return gpt?.profile_picture_url;

    }
    return "";
  },  [store]);
  return (
    <div>
      <div className="flex justify-between   relative  ">
        <div className="mt-[48px]">
          <h1
            onClick={onEdit}
            className="text-[4rem] leading-[4.75rem] cursor-pointer   "
          >
            {store?.name}
          </h1>
          <div className="link mt-4">{prop("description")(store)}</div>
           
          <div className="flex mt-4 mb-4 py-3  ">
            <div className="flex flex-col pr-[16px] items-center min-w-[96px]">
              <div className="meta-tag">{prop("reviews")(store)}</div>{" "}
              <div className="meta-label ">Views</div>
            </div>
             
          </div>
          <div className="flex items-center ">
            <div className="flex items-center ">
              <Share shareUrl={getShareLink(`/store/${store.id}`)}  title={store?.name} shareImage={cover}  />
            </div>
          </div>
        </div>
        <Avatar
          onClick={onEdit}
          className="absolute right-0 top-[48px] w-[240px] flex items-center justify-center  h-[240px] cover-shadow   "
          src={cover}
          icon={<AppstoreOutlined className="text-[120px] " />}
        />
      </div>
      <EditDetails
        store={store}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setActive={setActive}
      />
    </div>
  );
};

export default memo(Cover);
