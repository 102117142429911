import { Button } from "antd";
import { memo, useCallback } from "react";
import { track } from "../../hooks/useTrack";
import { stopPropagation, getGptLink } from "../../utils/link";

function Try({ gpt, type="default" }: { gpt: any, type?: "default" | "primary" }) {
  const goChat = useCallback(() => {
    track("use", gpt.id);
  }, [gpt]);

  return (
    <a
      href={getGptLink(gpt)}
      onClick={stopPropagation}
      target="_blank"
      rel="noreferrer"
    >
      <Button
        onClick={goChat}
        type={type}
        className="rounded-[20px] px-[20px] text-sm py-[4px]"
      >
        Try
      </Button>
    </a>
  );
}

export default memo(Try);
