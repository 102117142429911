import { memo, useCallback } from "react";
import { Avatar } from "antd";
import { PlusCircleOutlined, AppstoreOutlined } from "@ant-design/icons";
import { map, size } from "../../../utils/fp";
import { useStore } from "../../../store/store";
import { getCover } from "../../../utils/cdn";

interface Props {
  active: any;
  setActive: (active: any) => void;
}

const Nav = ({ active, setActive }: Props) => {
  const { store, newStore } = useStore();

  const onNewStore = useCallback(() => {
    const meta = {
      name: `Store #${size(store) + 1}`,
    };
    newStore(meta).then((res: any) => {
      setActive(res);
    });
  }, [setActive, store]);

  return (
    <div className="w-[300px]  h-full overflow-y-auto border-r ">
      <div className="  border-b  p-4  font-bold cursor-pointer">
        <div
          onClick={onNewStore}
          className="w-full  rounded-xl item-center  justify-center bg-[#f3f3f3] py-4 flex   shadow-[0_1px_7px_0px_rgba(0,0,0,0.06)]   "
        >
          <div>
            <PlusCircleOutlined className="text-brand-green  text-[16px] mr-1" />
            <span className="text-brand-green"> New</span>
          </div>
        </div>
      </div>
      {map((item: any) => (
        <div
          key={item?.id}
          onClick={() => {
            setActive(item);
          }}
          className={`${
            item?.id === active?.id && "bg-gray-100"
          } flex border-b border-gray-100 px-4  items-center py-4 cursor-pointer`}
        >
          <Avatar
            src={getCover(item)}
            className="mr-1 w-[36px] h-[36px] flex items-center justify-center "
            icon={<AppstoreOutlined className="text-[18px]" />}
          />

          <div className="flex items-center">
            <div className="">{item?.name}</div>
          </div>
        </div>
      ))(store)}
    </div>
  );
};

export default memo(Nav);
