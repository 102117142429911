import { memo, useState, useCallback, useMemo } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import AxiosService from "../../../api/AxiosService";
import { useStore } from "../../../store/store";
import UploadAvatar from "../../../components/Profile/UploadAvatar";
import { getCover } from "../../../utils/cdn";

type Props = {
  setActive: (res?: any) => void;
  store: any;

  children?: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const EditDetails = ({
  setActive,
  store,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { refreshStore } = useStore();

  const [notificationCall, contextHolder] = notification.useNotification();

  const initialValues = useMemo(() => {
    if (store) {
      return {
        name: store.name,
        cover: store.cover,
        description: store.description,
      };
    }

    return {};
  }, [store]);

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);

      AxiosService.post("/api/update-store", {
        ...store,
        ...values,
      })
        .then((res: any) => {
          if (res) {
            // eslint-disable-next-line
            console.log(res);
            setActive(res);
            refreshStore(res);
            hideModal();
          }
        })
        .catch(() => {
          notificationCall.error({
            message: "更新失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [store, setActive, notificationCall]
  );

  const onSaveAvatar = useCallback(
    (cover: string) => {
      form.setFieldsValue({
        cover,
      });
    },
    [form]
  );

  const initAvatar = useMemo(() => {
    if (store && store.cover) {
      return getCover(store);
    }
    return "";
  }, [store]);

  return (
    <div>
      {contextHolder}

      <Modal
        title="Edit details "
        open={isModalOpen}
        footer={null}
        width={524}
        onCancel={hideModal}
        zIndex={2000}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          <div className="flex mt-[20px]  justify-between  mb-[20px]">
            <div className="w-[180px] h-[180px]">
              <Form.Item name="cover">
                <UploadAvatar onUpload={onSaveAvatar} avatar={initAvatar} />
              </Form.Item>
            </div>
            <div className="w-[280px]">
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Store name is required." }]}
              >
                <Input placeholder="Add a name" />
              </Form.Item>
              <Form.Item name="description">
                <Input.TextArea
                  placeholder="Add an optional description"
                  autoSize={{ minRows: 3, maxRows: 10 }}
                />
              </Form.Item>
            </div>
          </div>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(EditDetails);
