import { memo } from "react";
import { map, noEmpty } from "../../../utils/fp";

const Description = ({ gpt }: any) => (
  <div>
    <div className="max-w-[800px] px-[16px] md:px-[0] ">
      <section className="flex flex-col pt-[20px] pb-[24px] ">
        <h2 className="h2  mb-[20px]">Description</h2>
        <div className="flex-1 desc whitespace-pre-wrap">
          {gpt?.description || "-"}
        </div>
      </section>
      {noEmpty(gpt?.prompt_starters) && (
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Prompt Starters</h2>
          <ul className="flex-1 desc whitespace-pre-wrap">
            {map((item: any) => <li>{item}</li>)(gpt?.prompt_starters)}
          </ul>
        </section>
      )}
      <section className="flex flex-col  pt-[20px] pb-[24px]">
        <h2 className="h2 mb-[20px]">Welcome Message</h2>
        <div className="flex-1 desc whitespace-pre-wrap">
          {gpt?.welcome_message}
        </div>
      </section>
      {noEmpty(gpt?.tools) && (
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Tools</h2>
          <div className="flex-1 desc whitespace-pre-wrap">
            {map(({ type }: any) => <div>{type}</div>)(gpt?.tools)}
          </div>
        </section>
      )}
    </div>
  </div>
);

export default memo(Description);
