import { useEffect } from "react";
import { noEmpty } from "../../utils/fp";
import TopGpts from "../../components/Gpts/TopGpts";
import { useSEO } from "../../hooks/useSeo";
import Nav from "./components/Nav";
import { useGptMap } from "./hooks/useGptMap";
import Loading from "../../components/Loading";
import { getShareLink } from "../../utils/link";

function Top() {
  const setSeo = useSEO();

  useEffect(() => {
    setSeo({
      title: "Top 50 Custom GPTs",
      description: "Top 50 Custom GPTs",
      keywords: "GPT Rankings, Best GPT, GPT-Store,   New GPTs, GPTS",
      ogUrl: `/top50`,
    });
  }, []);

  const current = "top50";
  const { top50 } = useGptMap();

  return (
    <div className="w-full max-w-[1288px] m-auto ">
      {noEmpty(top50) ? (
        <div>
          <Nav current={current} shareUrl={getShareLink('/top50')} > Top 50 Custom GPTs</Nav>
          <TopGpts gpts={top50} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Top;
