import { memo, useState, useCallback, useMemo, useEffect } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { isFunction,  } from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";

type Props = {
  setRole?: (key: any, res?: any) => void;
  callback?: () => void;
  character?: any;
  children?: React.ReactNode;
  isCopy?: boolean;
};

const Gpt = ({ setRole, callback, character, children, isCopy }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { newGpt } = useGptStore();

  useEffect(() => {
    if (character && !isCopy) {
      setIsEdit(true);
    }
  }, [character]);

  const [notificationCall, contextHolder] = notification.useNotification();

  const initialValues = useMemo(() => {
    if (character) {
      return {
        nickname: character.nickname,
        avatar: character.avatar,
        description: character.description,
        intro: character.intro,
      };
    }

    return {};
  }, [character]);

  const showModal = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setIsModalOpen(true);
      if (callback && isFunction(callback)) {
        callback();
      }
    },
    [callback]
  );

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);

      newGpt(values)
        
        .catch(() => {
          notificationCall.error({
            message: "创建失败",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [isEdit, character, newGpt, setRole, notificationCall]
  );

  const CreateButton = useMemo(() => {
    if (children) {
      return <div onClick={showModal}>{children}</div>;
    }
  }, [showModal, children]);

  return (
    <div>
      {contextHolder}
      {isEdit ? (
        <div onClick={showModal} className="cursor-pointer">
          {children || (
            <div className="flex p-1 ">
              <FormOutlined className="ml-1 text-gray-500  text-[16px]" />
            </div>
          )}
        </div>
      ) : (
        CreateButton
      )}

      <Modal
        title={isEdit ? "编辑角色" : "Submit GPT"}
        open={isModalOpen}
        footer={null}
        width={600}
        onCancel={hideModal}
        zIndex={2000}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Form.Item
            label="GPT URL"
            name="url"
            rules={[
              { required: true, message: "Please enter the URL for GPT." },
            ]}
          >
            <Input size="large" placeholder="https://chat.openai.com/g/g" />
          </Form.Item>

          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(Gpt);
