import { memo, useState, useEffect } from "react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { isEmpty, prop } from "../../utils/fp";
import { useStore } from "../../store/store";
import { useId } from "../../hooks/useId";
import AxiosService from "../../api/AxiosService";
import Cover from "./components/Cover";
import GptsInStore from "./components/GptsInStore";
import { useTrack } from "../../hooks/useTrack";
import { useSEO } from "../../hooks/useSeo";
import { getCover } from "../../utils/cdn";

const Details = () => {
  const { storeMap } = useStore();
  const [store, setStore] = useState<any>({});
  const id = useId();
  useTrack('store');
  const setSeo = useSEO();
  useEffect(() => {
    if (isEmpty(store) && !isEmpty(prop(id)(storeMap))) {
      setStore(prop(id)(storeMap));
    }
  }, [store, id]);

  useEffect(() => {
    AxiosService.get(`/api/get-store?id=${id}`).then((res: any) => {
      if (isEmpty(res)) {
        return;
      }
      setStore(res);
      setSeo({
        title: res.name,
        description: res.description,
        keywords: res.description,
        ogUrl: `/store/${res.id}`,
        ogImage: getCover(res),
      });
    });
  }, [id]);

  useDocumentTitle(store?.name);

  return (
    <div className="flex w-full h-full">
      <div className="flex-1  bg-white  relative overflow-y-auto  ">
        <div className="max-w-[1288px]  m-auto">
          <Cover store={store} />
          <GptsInStore store={store} />
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
