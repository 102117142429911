import { memo, useCallback, useMemo, useState } from "react";
import { Input, Avatar, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  noEmpty,
  prop,
  normalize,
  filter,
  has,
  mapIndexed,
  toUpper,
  includes
} from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";
import { useStore } from "../../../store/store";

const Gpts = ({ store, gpts }: any) => {
  const { gpt } = useGptStore();
  const { toStore } = useStore();
  const [keyword, setKeyword] = useState("");

  const onTo = useCallback(
    (item: any) => () => {
      toStore(item, store);
    },
    [toStore, store]
  );

  const addedMap = useMemo(() => normalize(gpts), [gpts]);

  const canAdd = useMemo(
    () => filter((item: any) => !has(prop("id")(item))(addedMap))(gpt),
    [addedMap]
  );

  const list = useMemo(() => {
    if (keyword) {
      return filter(
        (item: any) =>{
          const name = toUpper(item?.name);
          const description = toUpper(item?.description);
          const keywordUpper = toUpper(keyword);
          return includes(keywordUpper)(name) || includes(keywordUpper)(description)
        }
      )(canAdd);
    }
    return canAdd;
  }, [canAdd, keyword]);

  return (
    <div>
      <div className="max-w-[800px]">
        {noEmpty(gpts) && (
          <section className="flex flex-col  pt-[20px] pb-[24px]">
            <h2 className="h2 mb-[20px]">GPTs</h2>
            <ul className="flex-1 desc whitespace-pre-wrap">
              {mapIndexed((item: any, index: number) => (
                <li className="hover:bg-gray-100 flex  border-b border-gray-100 px-4  items-center py-4 cursor-pointer">
                  <div className="flex">
                    <div className="flex w-[32px] mr-2 text-[16px] items-center justify-center  ">
                      {index + 1}
                    </div>
                    <Avatar
                      src={item?.profile_picture_url}
                      className="mr-2 w-[36px] h-[36px]"
                    />
                    <div className="flex flex-col ">
                      <div className="title">{item?.name}</div>
                      <div className="label">{item?.description}</div>
                    </div>
                  </div>
                  <Button
                    className="rounded-[20px]  ml-auto"
                    onClick={onTo(item)}
                  >
                    Remove
                  </Button>
                </li>
              ))(gpts)}
            </ul>
          </section>
        )}
        <section className="flex flex-col  pt-[20px] pb-[24px]">
          <h2 className="h2 mb-[20px]">Let's find something for your store</h2>
          <Input
            size="large"
            placeholder="Search for GPT"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <ul className="flex flex-col">
            {mapIndexed((item: any, index: number) => (
              <li className="hover:bg-gray-100 flex  border-b border-gray-100 px-4  items-center py-4 cursor-pointer">
                <div className="flex">
                  <div className="flex w-[32px] mr-2 text-[16px] items-center justify-center  ">
                    {index + 1}
                  </div>
                  <Avatar
                    src={item?.profile_picture_url}
                    className="mr-2 w-[36px] h-[36px]"
                  />
                  <div className="flex flex-1 flex-col ">
                    <div className="title">{item?.name}</div>
                    <div className="label">{item?.description}</div>
                  </div>
                </div>
                <Button
                  className="rounded-[20px]  ml-auto"
                  onClick={onTo(item)}
                >
                  Add
                </Button>
              </li>
            ))(list)}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default memo(Gpts);
