import { memo, useEffect, useCallback, useMemo } from "react";
import { Button } from "antd";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

import { isEmpty, prop, map } from "../../utils/fp";
import { useGo } from "../../hooks/useGo";
import { useStore } from "../../store/store";
import Nav from "./components/Nav";
import Cover from "./components/Cover";
import Gpts from "./components/Gpts";
import { useGptStore } from "../../store/gpt";
import { useRouterGuards } from "../../hooks/useRouterGuards";

const Store = () => {
  useRouterGuards();
  const { store, active, setActive } = useStore();
  const { gptMap } = useGptStore();

  const gpts = useMemo(
    () => map((id: string) => prop(id)(gptMap))(active.gpts),
    [active]
  );

  useEffect(() => {
    if (store && isEmpty(active)) {
      setActive(store[0] || {});
    }
  }, [store, active]);

  const { go } = useGo();

  const onPreview = useCallback(() => {
    if (active.id) {
      go(`/store/${active.id}`);
    }
  }, [go, active]);

  useDocumentTitle("Your Store");

  return (
    <div className="flex w-full h-full">
      <Nav active={active} setActive={setActive} />

      <div className="flex-1  bg-white  relative overflow-y-auto  px-[72px]   ">
        <Button
          className="absolute left-[20px] top-[20px] z-10"
          onClick={onPreview}
        >
          Perview
        </Button>
        <Cover store={active} setActive={setActive} gpts={gpts} />
        <Gpts store={active} gpts={gpts} />
      </div>
    </div>
  );
};

export default memo(Store);
