import { Button } from "antd";
import { Link } from "react-router-dom";
import Share from "../../../components/Share/Share";

const getType = (key: string) => (current: string) =>
  current === key ? "primary" : "default";

function Nav({ current, children, shareUrl }: any) {
  return (
    <div>
      <div className="p-[20px]">
        <Link to="/top10">
          <Button
            type={getType("top10")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            Top 10
          </Button>
        </Link>
        <Link to="/top50">
          <Button
            type={getType("top50")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            Top 50
          </Button>
        </Link>
        <Link to="/top100">
          <Button
            type={getType("top100")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            Top 100
          </Button>
        </Link>
        <Link to="/top-chatgpt">
          <Button
            type={getType("chatGPT")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            ChatGPT
          </Button>
        </Link>
        <Link to="/top">
          <Button
            type={getType("all")(current)}
            className="mb-2 mr-2 rounded-[20px]"
          >
            All
          </Button>
        </Link>
      </div>
      <h1 className=" flex justify-between item-center text-[2rem] leading-[2.35rem] mb-[20px] px-[20px] ">
        {children}
        <Share title={children} shareUrl={shareUrl} />
      </h1>
    </div>
  );
}

export default Nav;
