import { memo, useState, useEffect } from "react";
import { useSEO } from "../../hooks/useSeo";
import { isEmpty, noEmpty, prop } from "../../utils/fp";
import { useGptStore } from "../../store/gpt";
import { useId } from "../../hooks/useId";
import AxiosService from "../../api/AxiosService";
import Cover from "./components/Cover";
import Description from "./components/Description";
import { useTrack } from "../../hooks/useTrack";

const Gpt = () => {
  const { gptMap } = useGptStore();
  const setSeo = useSEO();
  const [gpt, setGpt] = useState<any>({});
  const gptId = useId();
  useTrack("gpt");

  useEffect(() => {
    if (isEmpty(gpt) && !isEmpty(prop(gptId)(gptMap))) {
      setGpt(prop(gptId)(gptMap));
    }
  }, [gpt, gptId]);

  useEffect(() => {
    AxiosService.get(`/api/get-gpt?id=${gptId}`).then((res: any) => {
      if (noEmpty(res)) {
        setGpt(res);
        setSeo({
          title: res.name,
          description: res.description,
          keywords: res.description,
          ogUrl: `/gpt/${res.id}`,
          ogImage: res.profile_picture_url,
        });
      }
    });
  }, [gptId, setSeo]);

  return (
    <div className="flex w-full h-full">
      <div className="flex-1  bg-white  relative overflow-y-auto  ">
        <div className="max-w-[1288px]  m-auto">
          <Cover gpt={gpt} />
          <Description gpt={gpt} />
        </div>
      </div>
    </div>
  );
};

export default memo(Gpt);
