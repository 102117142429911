import { memo, useMemo } from "react";
import GptsList from "../../../components/Gpts";

import {
  map,
  noEmpty,
  prop,
  flow,
  compact,
} from "../../../utils/fp";
import { useGptStore } from "../../../store/gpt";

const Gpts = ({ store }: any) => {
  const { gptMap } = useGptStore();

  const gpts = useMemo(() => {
    if (store?.gptsData) {
      return store?.gptsData;
    }
    const list = flow(
      map((id: string) => prop(id)(gptMap)),
      compact
    )(store?.gpts);
    return list;
  }, [store]);

  return (
    <div>
      <div className="max-w-[800px]">
        {noEmpty(gpts) && (
          <section className="flex flex-col  pt-[20px] pb-[24px]">
            <h2 className="h2 mb-[20px]">GPTs</h2>
            <GptsList gpts={gpts} />
            
          </section>
        )}
      </div>
    </div>
  );
};

export default memo(Gpts);
