import { useEffect } from "react";
import { useTopGptStore } from "../../store/topGpt";
import { noEmpty } from "../../utils/fp";
import TopGpts from "../../components/Gpts/TopGpts";
import { useSEO } from "../../hooks/useSeo";
import Nav from "./components/Nav";
import { useGptMap } from "./hooks/useGptMap";
import Loading from "../../components/Loading";
import { getShareLink } from "../../utils/link";

function Top() {
  const { getTopGpt, gpts } = useTopGptStore();
  const setSeo = useSEO();

  useEffect(() => {
    setSeo({
      title: " ChatGPT GPTs",
      description: " ChatGPT GPTs",
      keywords: "GPT Rankings, Best GPT, GPT-Store,   New GPTs, GPTS",
      ogUrl: `/top10`,
    });
  }, [getTopGpt]);

  const current = "chatGPT";
  const { chatGPT } = useGptMap();

  return (
    <div className="w-full max-w-[1288px] m-auto ">
      {noEmpty(gpts) ? (
        <div>
          <Nav current={current} shareUrl={getShareLink("/top-chatgpt")}>
            {" "}
            ChatGPT GPTs
          </Nav>
          <TopGpts gpts={chatGPT} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Top;
